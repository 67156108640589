<template>
    <div id="impressumWraper">
        <div class="container">
            <h1>Impressum</h1>
            <p>Gerne sind wir persönlich für Sie da. Am einfachsten erreichen Sie uns per Email.<br />
                Hinterlassen Sie eine kurze Nachricht und Ihre Kontaktdaten. Wir setzen uns umgehend mit Ihnen in Verbindung.</p>
            <p>Angaben gemäß § 5 TMG</p>
            <p>Digital Mail Solution GmbH<br />
                Nächst Neuendorfer Landstraße 12<br />
                15806 Zossen OT Nächst Neuendorf</p>
            <p>Geschäftsführung: Rouven Stroß<br />
                Email: info@digital-mail-solution.com</p>
            <ul class="\">
                <li><span class="\">Handelsregister:  HRB 32493 P</span></li>
                <li><span class="\">Steuerliche Angaben:</span>
                    <ul>
                        <li>Steuernummer: 050/107/06620</li>
                        <li>USt-ID: DE317739227</li>
                    </ul>
                </li>
            </ul>
            <p>Haftungshinweis:</p>
            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: http://ec.europa.eu/consumers/odr Unsere E-Mail-Adresse finden Sie oben<br />
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p>Haftungshinweis:Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
            <p>Im Falle von wettbewerbsrechtlichen, domainrechtlichen, urheberrechtlichen, markenrechten oder aehnlichen Problemen bitten wir Sie, uns zur Vermeidung unnoetiger Rechtsstreite und Kosten bereits im Vorfeld zu kontaktieren. Die Kostennote einer anwaltlichen Abmahnung ohne vorhergehende Kontaktaufnahme wird im Sinne der Schadensminderungspflicht als unbegruendet zurueckgewiesen!</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Impressum"
    }
</script>

<style scoped>
    #impressumWraper{padding:25px 0;}
</style>